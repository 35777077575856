import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { CtaSimple } from '../components/CTABlock';
import { LearnContent } from '../components/LearnContent';

//main page
const DocsPage = () => {
	React.useEffect(() => window.scrollTo(0, 0), []);

	return (
		<Layout>
			<SEO pageTitle="Knowledge Base" description="Documentation for Embarkable" />

			<LearnContent />

			<div className="mt-20">
				<CtaSimple eventPageName="docsIndex" />
			</div>
		</Layout>
	);
};

export default DocsPage;
