import React, { useState } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

const LearnPageQuery = graphql`
	query LearnPageQuery {
		allMarkdownRemark(
			sort: { order: DESC, fields: frontmatter___date }
			filter: { frontmatter: { publish: { eq: true }, page: { eq: "learn" } } }
		) {
			edges {
				node {
					frontmatter {
						slug
						date
						title
						description
						image
						category
						publish
					}
				}
			}
		}
	}
`;

//menu and content
export const LearnContent = ({ children }) => {
	return (
		<StaticQuery
			query={LearnPageQuery}
			render={markdown => {
				const { allMarkdownRemark } = markdown;
				const [category, setCategory] = useState(null);

				//get categories from learn pages
				let categoryList = [];
				allMarkdownRemark.edges.forEach(element => {
					if (!categoryList.includes(element.node.frontmatter.category)) {
						categoryList.push(element.node.frontmatter.category);
					}
				});

				//filter by category
				var filteredList = allMarkdownRemark.edges.filter(
					element => element.node.frontmatter.category === category
				);
				if (filteredList.length === 0) {
					filteredList = allMarkdownRemark.edges;
				}

				return (
					<section className="relative flex flex-wrap justify-center w-full px-4 mx-auto mt-20 xl:mt-40 xxl:w-2/3">
						<LearnMenu
							categoryList={categoryList}
							selectedCategory={category}
							setCategory={setCategory}
						/>

						<div className="w-full px-6 lg:w-4/5">
							{children || (
								<div className="grid grid-cols-3 gap-10">
									{categoryList.map(eachCategory => {
										if (!category || category === eachCategory) {
											return (
												<React.Fragment key={eachCategory}>
													<div className="col-span-3">
														<Link
															to="/docs"
															className={`w-full mb-2 text-2xl text-gray-700 hover:underline`}
															onClick={() =>
																setCategory(eachCategory)
															}
														>
															{eachCategory}
														</Link>
													</div>
													{filteredList.map((edge, index) => {
														if (
															edge.node.frontmatter.category ===
															eachCategory
														) {
															return (
																<LearnCard
																	key={'key-' + index}
																	edge={edge}
																/>
															);
														}
													})}
												</React.Fragment>
											);
										}
									})}
								</div>
							)}
						</div>
					</section>
				);
			}}
		/>
	);
};

const LearnMenu = ({ categoryList, selectedCategory, setCategory }) => {
	return (
		<div className="block w-full mt-20 lg:w-1/5">
			<div className="sticky p-4 mx-auto mb-6 text-gray-600 bg-gray-100 xl:w-56 top-1/5">
				<div className="flex flex-wrap flex-auto justify-left">
					<div className="mb-4 font-semibold">Help Topics</div>

					<Link
						to="/docs"
						className={`w-full mb-2 ${
							selectedCategory === null && 'text-pink-400'
						} hover:underline`}
						onClick={() => setCategory(null)}
					>
						All
					</Link>
					{categoryList.map(category => {
						return (
							<Link
								key={category}
								to="/docs"
								className={`w-full mb-2 ${
									selectedCategory === category && 'text-pink-400'
								} hover:underline`}
								onClick={() => setCategory(category)}
							>
								{category}
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const LearnCard = data => {
	return (
		<div
			className={`relative overflow-hidden shadow-xl text-gray-700 cursor-pointer rounded-none ${
				data.isTitle ? 'col-span-3' : 'col-span-3 md:col-span-1'
			} hover:shadow-2xl link-on-hover`}
		>
			<Link to={data.edge.node.frontmatter.slug} tag={data.edge.node.frontmatter.slug}>
				<img
					className={`object-cover w-full ${data.isTitle ? 'h-80' : 'h-48'}`}
					src={data.edge.node.frontmatter.image}
					alt={data.edge.node.frontmatter.title}
				/>
				<div className="p-6">
					<div className="inline-block px-4 text-xs text-white bg-blue-400 rounded-full">
						{data.edge.node.frontmatter.category}
					</div>
					<div className="py-4 text-lg font-bold ">
						{data.edge.node.frontmatter.title}
					</div>
					{data.isTitle && (
						<p className="mt-1 font-light text-md">
							{data.edge.node.frontmatter.description}
						</p>
					)}
				</div>
			</Link>
		</div>
	);
};
